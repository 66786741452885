<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            공용 데이터 입력
          </v-card-title>
          <v-divider/>
        </v-card>
        <v-card>
          <v-list-item>
            <v-list-item-title>
              1. 이모지
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-row>
                <v-col cols="6">
                  <v-btn @click="read">조회</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn @click="add">추가</v-btn>
                  <v-text-field
                    v-model="iconName"
                    :append-outer-icon="iconName"
                  ></v-text-field>
                </v-col>
              </v-row>

            </v-list-item-title>
          </v-list-item>

          <v-row justify="space-around" style="margin-left:10px;margin-top:10px;">
            <v-card
              v-for="(item, index) in emojis" :key="index"
              flat
            >
              <v-btn
                fab outlined
                style="color: orangered"
                @click="del(item.name)"
              >
                <v-icon size="50"
                >{{ item.name }}</v-icon>
              </v-btn>
            </v-card>
          </v-row>
        </v-card>

      </v-col>
    </v-row>

    <v-card>
      {{ emojis }}
    </v-card>
  </v-container>
</template>
<script>
export default {
  data () {
    return {
      iconName: '',
      emojis: [
        // { name: 'mdi-cat' },
        // { name: 'mdi-cow' },
        // { name: 'mdi-duck' },
        // { name: 'mdi-dog' },
        // { name: 'mdi-fish' },
        // { name: 'mdi-owl' },
        // { name: 'mdi-paw' },
        // { name: 'mdi-panda' },
        // { name: 'mdi-pig' },
        // { name: 'mdi-penguin' },
        // { name: 'mdi-sheep' },
        // { name: 'mdi-turtle' },
        // { name: 'mdi-apple' },
        // { name: 'mdi-baguette' },
        // { name: 'mdi-car' },
        // { name: 'mdi-cake-variant' },
        // { name: 'mdi-cloud-check' },
        // { name: 'mdi-cookie' },
        // { name: 'mdi-egg' },
        // { name: 'mdi-gnome' },
        // { name: 'mdi-heart' },
        // { name: 'mdi-star' },
        // { name: 'mdi-sprout' },
        // { name: 'mdi-snowflake' }
      ]
    }
  },
  created () {
    this.read()
  },
  methods: {
    read () {
      this.emojis = []
      this.$firebase.firestore().collection('infos').doc('emojis').get()
        .then((r) => {
          // console.log(r.data().data.length)
          if (r.exists) {
            for (let i = 0; i < r.data().data.length; i++) {
              this.emojis.push(r.data().data[i])
            }
          }
        })
        .then(r => {
        })
    },
    add () {
      if (!this.iconName) return

      const dup = this.emojis.find(t => t.name === this.iconName)
      if (dup) {
        alert('dup')
        return
      }
      this.emojis.push({ name: this.iconName })

      this.$firebase.firestore().collection('infos').doc('emojis').update({
        data: this.emojis
      })
    },
    del (iconName) {
      console.log(iconName)
      const item = this.emojis.filter(t => t.name !== iconName)

      this.$firebase.firestore().collection('infos').doc('emojis').update({
        data: item
      })

      this.read()
    }
  }
}
</script>
